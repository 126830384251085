import React, { useState, useEffect } from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import Back from "../svg/go_back.svg"
import EmailIcon from "../svg/post_email.svg"
import TwitterIcon from "../svg/post_twitter.svg"
import InstagramIcon from "../svg/post_instagram.svg"
import FacebookIcon from "../svg/post_facebook.svg"
import Article from "../components/blog/Article"
import "../css/blog.css"
import PostEmailIcon from "../svg/email_footer.svg"
import PostTwitterIcon from "../svg/twitter.svg"
import PostInstagramIcon from "../svg/instagram.svg"
import PostFacebookIcon from "../svg/facebook.svg"

const isBrowser = typeof window !== "undefined"

const BlogPostTemplate = props => {
  const { data } = props
  const { previous, next, post } = data
  const posts = data.allWpPost.nodes.map(element => {
    return {
      ...element,
      categories: element.categories.nodes.filter(
        subElement => subElement.id === post.categories.nodes[0].id
      ),
    }
  })

  const filteredPosts = posts
    .filter(
      postItem => postItem.categories.length > 0 && postItem.id !== post.id
    )
    .slice(0, 3)

  if (isBrowser) {
    const scrollHandler = () => {
      if (window.scrollY > 100) {
        setShow(true)
      } else {
        setShow(false)
      }
    }

    window.addEventListener("scroll", scrollHandler)
  }

  const [show, setShow] = useState(false)

  return (
    <div id="single_post_blog">
      <Layout>
        <SEO title={post.title}/>
        <Header />
        {show ? (
          <div className={show ? "relative main-container  " : null}>
            <div className="bg-blue-400 main-container  fixed w-full z-50 top-0 ">
              <div className="flex justify-between items-center container mx-auto py-8 px-6">
                <div>
                  <h2 className="text-xl font-medium	 text-white mb-0">
                    {post.title}
                  </h2>
                </div>

                <div className=" hidden md:flex items-center justify-center">
                  <Link
                    to="https://twitter.com/appmanus"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <img
                      src={PostTwitterIcon}
                      className="w-7 lg:w-8 h-7 lg:h-9 mr-2 lg:mr-5 mb-0"
                      alt="Manus twitter"
                    />
                  </Link>
                  <Link
                    to="https://www.facebook.com/manusappofficial"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <img
                      src={PostFacebookIcon}
                      className="w-7 lg:w-8 h-7 lg:h-9 mr-2 lg:mr-5 mb-0"
                      alt="Manus facebook"
                    />
                  </Link>
                  <Link
                    to="https://www.instagram.com/manusapp"
                    rel="noreferrer"
                    target="_blank"
                  >
                    <img
                      src={PostInstagramIcon}
                      className="w-7 lg:w-8 h-7 lg:h-9 mr-2 lg:mr-5 mb-0"
                      alt="Manus instagram"
                    />
                  </Link>
                  <a href="mailto:help@manusapp.com">
                    <img
                      src={PostEmailIcon}
                      className="w-7 lg:w-8 h-7 lg:h-9 mb-0"
                      alt="Manus email"
                    />
                  </a>
                </div>
              </div>
             
            </div>
          </div>
        ) : null}
        <div className="main-container pt-12">
          <div className="container px-3 md:px-6">
            <div className="flex flex-row md:flex-col items-center md:items-start mb-7 ">
              <Link
                to="/blog"
                className="bg-green-400 w-8 h-8 flex justify-center  items-center rounded-full  hover:bg-green-500 transition "
              >
                <img src={Back} alt="prev-button" className="mb-0" />
              </Link>

              <span className="text-blue-600 capitalize text-base font-semibold ml-6 md:ml-0 md:mt-12">
                {post.categories.nodes[0].name}
              </span>
            </div>
            <h2 className="text-2xl lg:text-4-5xl text-black mt-5 mb-8 lg:mb-20">
              {post.title}
            </h2>
            <div className="flex items-center justify-between mt-2 mb-8">
              <div className="flex ">
                  {
                     post.SinglePost.singlePostAuthorPhoto ? (
                        <Image
                        fluid={
                          post.SinglePost.singlePostAuthorPhoto.localFile
                            .childImageSharp.fluid
                        }
                        alt={post.SinglePost.singlePostAuthorPhoto.altText}
                        className="rounded-full w-10 h-10"
                      />
                     ):
                     null
                  }
                <div className="flex flex-col   ml-2">
                  <p className="mb-0  text-gray-400 font-semibold text-sm">
                    {post.SinglePost.singelPostAuthor}
                  </p>
                  <p className="mb-0 text-gray-300 text-sm">
                    <span className="mr-2">{post.date}</span>
                    {post.SinglePost.singlePostTimeToRead}
                    {post.SinglePost.singlePostTimeToRead ? (
                      <span className="ml-1">read</span>
                    ) : null}
                  </p>
                </div>{" "}
              </div>

              <div className="flex items-center  ">
                <Link
                  to="https://twitter.com/appmanus"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img
                    src={TwitterIcon}
                    className="w-7 lg:w-8 h-7 lg:h-9 mr-2 lg:mr-5 mb-0"
                    alt="Manus twitter"
                  />
                </Link>
                <Link
                  to="https://www.facebook.com/manusappofficial"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img
                    src={FacebookIcon}
                    className="w-7 lg:w-8 h-7 lg:h-9 mr-2 lg:mr-5 mb-0"
                    alt="Manus facebook"
                  />
                </Link>
                <Link
                  to="https://www.instagram.com/manusapp"
                  rel="noreferrer"
                  target="_blank"
                >
                  <img
                    src={InstagramIcon}
                    className="w-7 lg:w-8 h-7 lg:h-9 mr-2 lg:mr-5 mb-0"
                    alt="Manus instagram"
                  />
                </Link>
                <a href="mailto:help@manusapp.com">
                  <img
                    src={EmailIcon}
                    className="w-7 lg:w-8 h-7 lg:h-9 mb-0"
                    alt="Manus email"
                  />
                </a>
              </div>
            </div>
            {
              post.SinglePost.singlePostImage ? 
              <Image
              fluid={
                post.SinglePost.singlePostImage.localFile.childImageSharp.fluid
              }
              alt={post.SinglePost.singlePostImage.altText}
              className="rounded-lg mb-7 h-56 md:h-high w-full"
            />
            : ''
            }
            <div
              className="text-gray-500 text-sm lg:text-base leading-7.5  "
              dangerouslySetInnerHTML={{
                __html: post.SinglePost.singlePostContent,
              }}
            ></div>
            {show ? (
              <div className="flex mt-6">
                  {
                    post.SinglePost.singlePostAuthorPhoto ? (
                      <Image
                      fluid={
                        post.SinglePost.singlePostAuthorPhoto.localFile
                          .childImageSharp.fluid
                      }
                      alt={post.SinglePost.singlePostAuthorPhoto.altText}
                      className="rounded-full w-10 h-10"
                    />
                    ) : null
                  }
              
                <div className="flex flex-col   ml-2">
                  <p className="mb-0  text-gray-400 font-semibold text-sm">
                    {post.SinglePost.singelPostAuthor}
                  </p>
                  <p className="mb-0 text-gray-300 text-sm">
                    <span className="mr-2">{post.date}</span>
                    {post.SinglePost.singlePostTimeToRead}
                    {post.SinglePost.singlePostTimeToRead ? (
                      <span className="ml-1">read</span>
                    ) : null}
                  </p>
                </div>{" "}
              </div>
            ) : null}
            <div className="grid grid-cols-12 mt-7 mb-12 gap-x-3">
              <div className="col-span-5 w-full h-1 rounded-2xl bg-green-400  "></div>
              <div className="col-span-7 h-1 rounded-2xl bg-blue-500"></div>
            </div>
            <h2 className="text-center text-blue-400 font-bold mb-12 text-4xl	">
              Other articles
            </h2>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-14">
              {filteredPosts ? (
                filteredPosts.map(post => (
                  <Article
                    key={post.uri}
                    category={post?.categories[0]?.name}
                    title={post?.title}
                    excerpt={post?.SinglePost?.singlePostExcerpt}
                    authPic={
                      post?.SinglePost?.singlePostAuthorPhoto?.localFile
                        ?.childImageSharp.fluid
                    }
                    auth={post?.SinglePost?.singelPostAuthor}
                    date={post?.date}
                    time={post?.SinglePost?.singlePostTimeToRead}
                    link={post?.uri}
                  />
                ))
              ) : (
                <h2 className=" text-blue-400 font-bold mb-12 text-4xl	">
                  No related articles
                </h2>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      slug
      content
      title
      uri
      date(formatString: "MMMM DD, YYYY")
      categories {
        nodes {
          slug
          name
          id
        }
      }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 100) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
      SinglePost {
        singelPostAuthor
        singlePostContent
        singlePostExcerpt
        singlePostTimeToRead
        singlePostAuthorPhoto {
          altText
          localFile {
            childImageSharp {
              fluid {
                base64
                tracedSVG
                srcWebp
                srcSetWebp
                originalImg
                originalName
              }
            }
          }
        }
        singlePostImage {
          altText
          localFile {
            childImageSharp {
              fluid {
                base64
                tracedSVG
                srcWebp
                srcSetWebp
                originalImg
                originalName
              }
            }
          }
        }
      }
    }

    allWpPost(sort: { fields: [date], order: DESC }) {
      nodes {
        excerpt
        id
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        categories {
          nodes {
            name
            id
          }
        }
        SinglePost {
          singelPostAuthor
          singlePostContent
          singlePostExcerpt
          singlePostTimeToRead
          singlePostAuthorPhoto {
            altText
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          singlePostImage {
            altText
            localFile {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }

    # this gets us the previous post by id (if it exists)
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }

    # this gets us the next post by id (if it exists)
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`
